jQuery(document).ready(function ($) {
  $('.partner-slider').slick({
    infinite: true,
    dots: false,
    // arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: document.getElementById('rigth-slide-arrow'),
    prevArrow: document.getElementById('left-slide-arrow'),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  });

  $('.testimonial-slider').slick({
    vertical: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
  });

  $('.open-search ').click(function () {
    $(this).toggleClass('opened');
    $('.search-form ').slideToggle();
  });

  $('#menu-btn').click(function () {
    $(this).toggleClass('open');
    $('.primary-menu').slideToggle();
  });

  $(function() {
    $('.primary-menu a[href*="#"]:not([href="#"])').click(function() {
      $('html, body').animate({
        scrollTop: $("#page-tabs").offset().top
      }, 2000);

    });
  });

  $(window).load(function(){
    // Remove the # from the hash, as different browsers may or may not include it
    var hash = location.hash.replace('#','');

    if(hash != ''){

      // Clear the hash in the URL
      // location.hash = '';   // delete front "//" if you want to change the address bar
      $('html, body').animate({ scrollTop: $('#page-tabs').offset().top}, 1000);

    }
  });

  if ($('.responsiveTabs').length) {
    $('.responsiveTabs').responsiveTabs({
      rotate: false,
      startCollapsed: 'accordion',
      collapsible: 'accordion',
      setHash: true,
      animation: 'slide',
      scrollToAccordion: true,
      duration: 600,
      // animation: 'fade'
    });
  }



  if ($('select').length) {
    $('select').selectric({
      disableOnMobile: false,
      nativeOnMobile: false
    });
  }

  $('.primary-menu .menu-item-has-children').append($('<i>', {class: 'fa fa-caret-down'}));

  $('.primary-menu .fa-caret-down').click(function () {
    $(this).siblings('.sub-menu').slideToggle();
    $(this).closest('.menu-item-has-children').siblings().find('.sub-menu').slideUp();
  });

  var wow = new WOW({
    boxClass: 'wow', // animated element css class (default is wow)
    animateClass: 'animated', // animation css class (default is animated)
    offset: 0, // distance to the element when triggering the animation (default is 0)
    mobile: false, // trigger animations on mobile devices (default is true)
    live: true, // act on asynchronously loaded content (default is true)
    callback: function (box) {
      // the callback is fired every time an animation is started
      // the argument that is passed in is the DOM node being animated
    },
    scrollContainer: null // optional scroll container selector, otherwise use window
  });

  wow.init();

  // Load more Button
  $(document).on( 'click', '.load-more-btn a', function( event ) {
    event.preventDefault();
    var button = $(this);

    page = button.data('next_page');

    $.ajax({
      dataType: "json",
      url: na_ajaxpagination.ajaxurl,
      type: 'post',
      data: {
        action: 'ajax_pagination',
        query_vars: na_ajaxpagination.query_vars,
        page: page
      },
      success: function( response ) {
        var $items = $(response.html);
        // append items to grid
        $grid.append( $items )
            .masonry( 'appended', $items );

        if(response.next_page) {
          button.data('next_page', response.next_page);
        } else {
          button.remove();
        }
      }
    })
  });


  $('#bookmark-this').click(function(e) {
    var bookmarkURL = window.location.href;
    var bookmarkTitle = document.title;

    if (window.sidebar && window.sidebar.addPanel) {
      // Firefox <=22
      window.sidebar.addPanel(bookmarkTitle, bookmarkURL, '');
    } else if ((window.sidebar && /Firefox/i.test(navigator.userAgent)) || (window.opera && window.print)) {
      // Firefox 23+ and Opera <=14
      $(this).attr({
        href: bookmarkURL,
        title: bookmarkTitle,
        rel: 'sidebar'
      }).off(e);
      return true;
    } else if (window.external && ('AddFavorite' in window.external)) {
      // IE Favorites
      window.external.AddFavorite(bookmarkURL, bookmarkTitle);
    } else {
      // Other browsers (mainly WebKit & Blink - Safari, Chrome, Opera 15+)
      alert('Press ' + (/Mac/i.test(navigator.userAgent) ? 'Cmd' : 'Ctrl') + '+D to bookmark this page.');
    }

    return false;
  });


});

var $grid;
jQuery(window).load(function () {
  if (jQuery('.grid').length) {
    $grid = jQuery('.grid').masonry({
      itemSelector: '.grid-item',
      percentPosition: true,
      columnWidth: '.grid-sizer',
      horizontalOrder: true,
      gutter: 0
    });
  }


  iframeDimensions();

});

jQuery(window).resize(function () {

  iframeDimensions();

});

function iframeDimensions() {
  jQuery('iframe').each(function () {
    var iWid = jQuery(this).width();
    jQuery(this).css('height', iWid * 0.56);
  });
}

//gravity
jQuery(document).bind('gform_post_render', function(){
  jQuery('select').selectric({
    disableOnMobile: false,
    nativeOnMobile: false
  });
});